import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Content from '../Components/Content';
import MissionSection from '../Components/MissionSection';

export default function Main() {
  return (
    <>
    <Header />
    <MissionSection />
    <Content />
    <Footer />
    </>
  )
}
