import React, { useState } from 'react'

import CardCss from '../CSS/Card.css';
import doctorIcon from '../Assets/Icons/doctor.png';
import surgeryIcon from '../Assets/Icons/surgery.png'
import gynoIcon from '../Assets/Icons/baby.png';
import dentalIcon from '../Assets/Icons/dentist.png';
import ultrasoundIcon from '../Assets/Icons/ultrasound.png';
import xrayIcon from '../Assets/Icons/x-rays.png';
import labIcon from '../Assets/Icons/laboratory.png';
import pharmacyIcon from '../Assets/Icons/drugstore.png';

export default function Cards(props) {
    const [cardData, setCardData] = useState([
        {
            IconName: doctorIcon, 
            cardText: "General physician (Cudurada Guud)", 
            cardTextInfo: "We offer services pertaining to general health"
        },
        {
            IconName: surgeryIcon,
            cardText: "General surgery (Qalliinka Guud)",
            cardTextInfo: "We offer services pertaining to surgery"
        },
        {
            IconName: gynoIcon,
            cardText: "Gynecology and Delivery (Dumarka & Dhalmada",
            cardTextInfo: "We offer services pertaining to gynecology and delivery "
        },
        {
            IconName: dentalIcon,
            cardText: "Dental (Ilkaha)",
            cardTextInfo: "We offer services pertaining to dental health"
        },
        {
            IconName: ultrasoundIcon,
            cardText: "Ultrasound (Kombyutarka)",
            cardTextInfo: "We offer services pertaining to ultrasound scanning"
        },
        {
            IconName: xrayIcon,
            cardText: "X-ray department (Q.Sawirka Lafaha)",
            cardTextInfo: "We offer services pertaining to X-ray"
        },
        {
            IconName: labIcon,
            cardText: "Laboratory department (Q.Sawirka Lafaha)",
            cardTextInfo: "We offer services pertaining to laboratory work"
        },
        {
            IconName: pharmacyIcon,
            cardText: "Pharmacy department (Q.Farmasiga)",
            cardTextInfo: "We offer services pertaining to pharmacy and medication"
        },
    ]);

  return (
    <div className='cardContainer'>
        {cardData.map((data) => (
        <div className="card">
            <img className='icons' src={data.IconName} alt='icon'/>
            <div className="cardInfo">
                <p className='cardText'>{data.cardText}</p>
                <p className='cardTextInfo'>{data.cardTextInfo}</p>
            </div>
        </div>
        ))}
    </div>
  )
}


/*
    <div className='cardContainer'>
        {cardData.map((data) => (
        <div className="card">
            <img className='icons' src={data.IconName} alt='icon'/>
            <div className="cardInfo">
                <p className='cardText'>{data.cardText}</p>
                <p className='cardTextInfo'>{data.cardTextInfo}</p>
            </div>
        </div>
        ))}
    </div>
*/