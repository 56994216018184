import React from 'react';
import headerCss from '../CSS/Header.css';
import logo from '../Assets/logonew.png';
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export default function Header() {
  return (
    <header>
      <nav>
      <h1>ABBY HOSPITAL</h1>
        <ul>
            <li>
            <Link activeClass="/" to="/" spy={true} smooth={true} offset={50} duration={500}>
          Home
          </Link>
            </li>
            <li>
              <Link activeClass="/" to="mission" spy={true} smooth={true} offset={50} duration={500}>
              Mission
              </Link>
          </li>
            <li>
            <Link activeClass="/" to="services" spy={true} smooth={true} offset={50} duration={500}>
              Our Servcies
            </Link>
            </li>
        </ul>
      </nav>
      <div className="maintext" id='/'>
        <h2>Making Health Care Better Together </h2>
        <p>We strive to provide care with dignity, kindess and respect, to enhance the health of the community and improve the quality of the lives of our patients</p>
        <button>              
          <Link activeClass="/" to="mission" spy={true} smooth={true} offset={50} duration={500}>
              Read more
          </Link>
        </button>
      </div>
    </header>
  )
}
