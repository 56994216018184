import React from 'react';
import footerCss from '../CSS/Footer.css'

export default function Footer() {
  return (
    <footer>
      <h1>ABBY HOSPITAL</h1>
      <h3 className='headingFooter'>You're Welcome to contact or visit us</h3>
      <h3 className='headingFooter'>0025263788711 / 0025265788711 / 00252741006</h3>
      <h3 className='headingFooter'>contact@abbyhospital.com</h3>
      <h3 className='centerCityName'>Sha'ab Area, Berbera, Somaliland</h3>
    </footer>
  )
}
