import React from 'react';
import ContentCSS from '../CSS/Content.css';
import Card from '../Components/Cards';

export default function Content() {

  return (
    <div className="content">
        <div className="cardWrapper">
          <div className="headerWrapper" id='services'>
            <h1>Our services</h1>
          </div>
            <Card />
        </div>
    </div>
  )
}
