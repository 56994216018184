import React, { useState } from 'react'
import ContentCSS from '../CSS/Content.css';
import img1 from '../Assets/images/img1.jpg';
import img2 from '../Assets/images/img2.jpg';
import img3 from '../Assets/images/img3.jpg';
import img4 from '../Assets/images/img4.jpg';
import img5 from '../Assets/images/img5.jpg';
import img6 from '../Assets/images/img6.jpg';
import img7 from '../Assets/images/img7.jpg';
import img8 from '../Assets/images/img8.jpg';
import img9 from '../Assets/images/img9.jpg';
import img10 from '../Assets/images/img10.jpg';
import img11 from '../Assets/images/img11.jpg';
import img12 from '../Assets/images/img12.jpg';
import img13 from '../Assets/images/img13.jpg';
import img14 from '../Assets/images/img14.jpg';
import img15 from '../Assets/images/img15.jpg';
import img16 from '../Assets/images/img16.jpg';
import img17 from '../Assets/images/img17.jpg';
import img18 from '../Assets/images/img18.jpg';
import img19 from '../Assets/images/img19.jpg';
import img20 from '../Assets/images/img20.jpg';

export default function MissionSection() {
    const [imgData, setImgData] = useState([
        {imgHsp: img1},
        {imgHsp: img2},
        {imgHsp: img3},
        {imgHsp: img4},
        {imgHsp: img5},
        {imgHsp: img6},
        {imgHsp: img8},
        {imgHsp: img11},
        {imgHsp: img12},
        {imgHsp: img13},
        {imgHsp: img14},
        {imgHsp: img15},
        {imgHsp: img16},
        {imgHsp: img17},
        {imgHsp: img18},
        {imgHsp: img20},
    ])

  return (
    <div className='missionSection' id='mission'>
        <div className="wrapper">
            <h1>Mission</h1>
            <p>Abby hospital is a hospital in Berbera, Somaliland. Our mission is to provide high quality care to the people of the region.<br/> We strive to serve the community and contribute to society in a way that will transform the lives of the people.<br/> The focus is to provide good quality healthcare to each and every patient.</p>
        </div>
        <div className="imgWrapper">
            {imgData.map((data) => (
                <img className='hspImages' src={data.imgHsp} alt="img" />
            ))}
        </div>
    </div>
  )
}

